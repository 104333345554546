.certificate {
  cursor: pointer;
  width: 80px;
  height: auto;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

.certificate:hover {
  border: 2px solid #333;
  box-shadow: 0px 0px 20px 0 rgba(255, 255, 255, 0.5);
}

.experienceListContainer {
  /* You can add any global styles for the container here */
}

.experienceListContainer .experienceList {
  width: 100%;
}

.experienceListContainer .experienceHeader {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 20px;
  padding: 10px 0;
  color: #262626;
  border-radius: 10px;
}

.experienceListContainer .headerItem {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.experienceListContainer .icon {
  margin-right: 8px;
}

.experienceListContainer .experienceItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
  padding: 15px 0;
}

.experienceListContainer .experienceContent {
  flex: 1;
  padding: 0 10px;
}

.experienceListContainer .contentTitle {
  display: none;
}

@media (max-width: 600px) {
  .experienceListContainer .experienceHeader {
    display: none;
  }

  .experienceListContainer .experienceItem {
    flex-direction: column;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 15px;
    margin-top: 15px;
  }

  .experienceListContainer .experienceContent {
    margin-bottom: 15px;
  }

  .experienceListContainer .contentTitle {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
}
