.nav-tabs .nav-link {
  background-color: white;
}

.nav-tabs .nav-link {
  border: none;
  width: 171px;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .nav-tabs .nav-link {
    width: auto;
  }
}

.nav-tabs .nav-link.active {
  background-color: #2561b0;
  color: white;
}

.nav-tabs .nav-item {
  text-align: center;
}

.nav-tabs .nav-link {
  color: #000;
  font-size: 13px;
  font-weight: 500;
}
