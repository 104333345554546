@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

html,
body,
#root {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}
Ø
.text-grey {
  color: #262626;
}

.text-lightgrey {
  color: #bfbfbf;
}

.MuiStepIcon-root.MuiStepIcon-completed {
  color: #00c853 !important;
}

.MuiStepLabel-root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hoverRadio:hover {
  border: 1px #296dc4 solid;
  border-radius: 0.5rem;
}

.hoverRadioBorder {
  border: 1px #296dc4 solid;
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.fw-semibold {
  font-weight: 450 !important;
}

.hoverRadioNoBorder {
  border: 1px #d9d9d9 solid;
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.required-border {
  border-color: #fa5a16;
}

.MuiFormLabel-root {
  line-height: 2.4375em !important;
}

.MuiListItemButton-root {
  padding: 10px !important;
  border-color: #fa5a16 !important;
}
